$primary: #1fc36a;
$primary1: #1fc36a;
$primary2: #216869;
$primary3: #5c4ae4;
$gray1: #444444;
$gray2: #666666;
$gray3: #888888;
$gray4: #cccccc;
$gray5: #eeeeee;
$gray6: #efefef;

$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;



.h-tag-nav-content img {
	width: 100%; /* .w-full */
}

.h-tag-nav-content li {
	width: 100%;
	word-break: break-all;
}

.h-tag-nav-wrap {

	position: sticky;
	top: 70px;
	.toc-head {
		background: none;
		border: 0;
		width: 100%;
		text-align: left;
		padding: 0;

		@media only screen and (min-width: $break-md) {
			background: none;
			pointer-events: none;
			text-align: left;
			padding: 0;
			font-size: 1.15em;
			font-weight: 600;
		}
		img {
			width: 16px;
			margin-right: 5px;
		}
	}
	.collapse {

		width: 100%;

		@media only screen and (max-width: $break-md) {
			display: none;
			&.show {
				display: inline-block;
			}
		}
	}
	ul {
		margin: 0;
		padding: 0;
		ul {
			margin: 0 0 0 10px;
		}
	}
	li {
		margin: 0 0 5px 0;
	}
	.active a {
		color: #1ec36a; /* .text-green-500 */
		font-weight: bold; /* .font-bold */
	}
}
